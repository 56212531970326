import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";

const UpdateVacatingPage = () => {
  const navigate = useNavigate();
  const [repairItems, setRepairItems] = useState([{ item: "", price: "" }]);
  const [totalAmount, setTotalAmount] = useState("");

  // Function to calculate total amount
  const calculateTotalAmount = () => {
    let total = 0;
    repairItems.forEach((item) => {
      if (item.price) {
        total += parseFloat(item.price);
      }
    });
    setTotalAmount(total.toFixed(2));
  };

  useEffect(() => {
    calculateTotalAmount(); // Calculate total amount initially
  }, [repairItems]); // Recalculate total amount whenever repairItems change

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const list = [...repairItems];
    list[index][name] = value;
    setRepairItems(list);
  };

  const handleAddItem = () => {
    setRepairItems([...repairItems, { item: "", price: "" }]);
  };

  const handleRemoveItem = (index) => {
    const list = [...repairItems];
    list.splice(index, 1);
    setRepairItems(list);
  };

  const handleSubmit = () => {
    // Send patch request to update vacating house data
    const vacatingId = localStorage.getItem("selectedVacatingId");
    const token = localStorage.getItem("token");
    const requestData = {
      repaircharges: repairItems
        .map((item) => `${item.item} ${item.price}`)
        .join(", "), // Concatenate repair charges
      totalamountrepairs: totalAmount, // Use total amount calculated dynamically
    };

    axios
      .patch(
        `https://backend.inyumba.co.ke/iNyumba/vacatinghouse/${vacatingId}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);
        navigate(-1);
      })
      .catch((error) => {
        toast.error(error.response.data.response);
        console.error("Error updating vacating house data:", error);
      });
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Update Vacating House</h1>
        {repairItems.map((item, index) => (
          <div key={index} className="flex mb-4">
            <input
              type="text"
              name="item"
              value={item.item}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="Item destroyed"
              className="w-1/2 border rounded-md py-2 px-3 mr-2"
            />
            <input
              type="number"
              name="price"
              value={item.price}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="Price of item destroyed"
              className="w-1/4 border rounded-md py-2 px-3 mr-2"
            />
            <button
              onClick={() => handleRemoveItem(index)}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            >
              Remove
            </button>
          </div>
        ))}
        <div className="mb-4">
          <button
            onClick={handleAddItem}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Add Item
          </button>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold">Total Amount:</label>
          <input
            type="text"
            value={totalAmount}
            readOnly
            className="w-full border rounded-md py-2 px-3"
          />
        </div>
        <button
          onClick={handleSubmit}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Update Vacating House
        </button>
      </div>
    </AuthGuard>
  );
};

export default UpdateVacatingPage;
