import React from "react";
import { useNavigate } from "react-router-dom"; // Import navigate
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";

const DeletePhoto = ({ photoId }) => {
  const navigate = useNavigate(); // Initialize navigate

  const handleDelete = () => {
    const photoId = localStorage.getItem("deletePhotoId");
    const token = localStorage.getItem("token");
    // Perform delete request
    axios
      .delete(
        `https://backend.inyumba.co.ke/iNyumba/photos/delete/${photoId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);
        // Navigate back
        navigate(-1);
      })
      .catch((error) => {
        toast.error(error.response.data.response);
        console.error("Error deleting photo:", error);
        // Navigate back
        navigate(-1);
      });
  };

  const handleCancel = () => {
    // Navigate back
    navigate(-1);
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Delete Photo</h1>
        <p>Are you sure you want to delete this photo?</p>
        <button
          onClick={handleDelete}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2"
        >
          Yes
        </button>
        <button
          onClick={handleCancel}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
        >
          No
        </button>
      </div>
    </AuthGuard>
  );
};

export default DeletePhoto;
