import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faMobileAlt,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons"; // Import icons
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { Oval } from "react-loader-spinner"; // Import loader

const DepositsPage = () => {
  const [balance, setBalance] = useState(null);
  const [showBalance, setShowBalance] = useState(true);
  const [amount, setAmount] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [depositAmount, setDepositAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [webUrl, setWebUrl] = useState("");
  const [showIframe, setShowIframe] = useState(false);

  const token = localStorage.getItem("token");
  const propertyId = localStorage.getItem("selectedPropertyId");
  const navigate = useNavigate();

  useEffect(() => {
    if (token && propertyId) {
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/balance/owner/${propertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setBalance(
            response.data.response.length > 0
              ? response.data.response[0].balance
              : 0
          );
        })
        .catch((error) => {
          console.error("Error fetching balance:", error);
        });
    }
  }, [token, propertyId]);

  useEffect(() => {
    setDepositAmount((amount * 0.98).toFixed(2));
  }, [amount]);

  const toggleShowBalance = () => {
    setShowBalance(!showBalance);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        `https://backend.inyumba.co.ke/iNyumba/initiatepayment/${propertyId}`,
        {
          amount,
          phone_number: phoneNumber,
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        setWebUrl(response.data.response);
        toast.success("Payment initiated successfully!");
        setShowIframe(true);
      } else {
        toast.error(response.data.response || "Payment initiation failed!");
      }
    } catch (error) {
      toast.error(error.response.data.response);
    }
    setIsLoading(false);
  };

  const handleCloseIframe = () => {
    setShowIframe(false); // Hide the iframe
    setWebUrl(""); // Clear the payment URL
    navigate("/landlord/deposits");
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-3xl font-semibold">Deposits</h1>
          <div className="flex items-center">
            {showBalance ? (
              <p className="mr-4">
                Balance: {balance !== null ? `Ksh ${balance}` : "0"}
              </p>
            ) : (
              <p className="mr-4">
                Balance: {balance !== null ? "*******" : "0"}
              </p>
            )}
            <FontAwesomeIcon
              icon={showBalance ? faEyeSlash : faEye}
              className="text-xl cursor-pointer"
              onClick={toggleShowBalance}
            />
          </div>
        </div>

        <div className="bg-white shadow-md rounded-md p-6">
          <h2 className="text-xl font-semibold mb-4">Deposits</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Email
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter email"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Phone Number
              </label>
              <input
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Use 254 or 07 dont add +"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Amount
              </label>
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter amount"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Actual Amount to be Deposited. Rates apply from iPay.
              </label>
              <p className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight">
                Ksh {depositAmount}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <button
                type="submit"
                className={`bg-blue-500 text-white px-4 py-2 rounded ${
                  isLoading
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-blue-600"
                }`}
                disabled={isLoading}
              >
                {isLoading ? (
                  <Oval height={20} width={20} color="#fff" />
                ) : (
                  "Initiate Depositing"
                )}
              </button>
            </div>
          </form>
        </div>
        {/* Conditionally render the iframe with close button */}
        {showIframe && webUrl && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex flex-col items-center justify-center">
            <button
              onClick={handleCloseIframe}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mb-4"
            >
              Close
            </button>
            <iframe
              src={webUrl}
              className="w-screen h-screen border-none mx-auto"
              title="Payment Iframe"
            ></iframe>
          </div>
        )}
      </div>
    </AuthGuard>
  );
};

export default DepositsPage;
