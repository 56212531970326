import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faUser,
  faBell,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";

const Home = () => {
  const navigate = useNavigate();
  const [properties, setProperties] = useState([]);
  const [showAddPropertyForm, setShowAddPropertyForm] = useState(false);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    location: "",
    photo: "",
    county: "",
    owner_id: "", // Will be replaced with userId from local storage
  });

  useEffect(() => {
    // Retrieve the JSON string from localStorage
    const userDataString = localStorage.getItem("user");

    // Parse the JSON string to convert it into a JavaScript object
    const userData = JSON.parse(userDataString);

    // Access the 'unique' property from the 'user' object
    const userId = userData.unique;

    const token = localStorage.getItem("token");

    if (token && userId) {
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/admin/properties/landlordproperties/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setProperties(response.data.response);
        })
        .catch((error) => {
          console.error("Error fetching properties:", error);
        });
      // Fetch notifications count
      axios
        .get(
          "https://backend.inyumba.co.ke/iNyumba/inappnotifications/viewallnotifications",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setNotificationsCount(response.data.response.length);
        })
        .catch((error) => {
          console.error("Error fetching notifications:", error);
        });
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddProperty = () => {
    setShowAddPropertyForm(true);
  };

  // Add a state variable to track whether photo upload is complete
  const [photoUploaded, setPhotoUploaded] = useState(false);

  const handleUploadPhoto = async (e) => {
    const file = e.target.files[0];
    const formDataForUpload = new FormData();
    formDataForUpload.append("image", file);
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        "https://backend.inyumba.co.ke/iNyumba/images/images/upload",
        formDataForUpload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setFormData((prevState) => ({
        ...prevState,
        photo: response.data.response.coverUri,
      }));

      // Set photoUploaded to true after photo upload is complete
      setPhotoUploaded(true);
    } catch (error) {
      console.error("Error uploading photo:", error);
    }
  };

  // Modify handleSubmitProperty to check if photo is uploaded before submission
  const handleSubmitProperty = async (e) => {
    e.preventDefault();

    // Check if photo upload is complete before proceeding
    if (!photoUploaded) {
      console.error("Please wait for the photo to upload.");
      return;
    }

    try {
      const userDataString = localStorage.getItem("user");
      const userData = JSON.parse(userDataString);
      const userId = userData.unique;
      const token = localStorage.getItem("token");

      const response = await axios.post(
        "https://backend.inyumba.co.ke/iNyumba/admin/properties/post",
        {
          ...formData,
          owner_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Add new property to the list of properties
      setProperties([...properties, response.data.response]);

      // Reset the form data to its initial values
      setFormData({
        title: "",
        description: "",
        location: "",
        photo: "",
        county: "",
        owner_id: "", // Will be replaced with userId from local storage
      });

      // Hide the add property form
      setShowAddPropertyForm(false);

      // Reset photoUploaded state after successful submission
      setPhotoUploaded(false);
      toast.success(response.data.response);
    } catch (error) {
      toast.error(error.response.data.response);
      console.error("Error adding property:", error);
    }
  };

  const handleProfileClick = () => {
    // Redirect to profile screen
    navigate("/Landlord/profile");
  };

  const handleLogoutClick = () => {
    // Redirect to logout screen
    navigate("/logout");
  };

  const handlePropertyClick = (property_id) => {
    localStorage.setItem("selectedPropertyId", property_id);
    navigate(`/landlord/propertypage`);
  };

  const handleNotificationsClick = () => {
    // Redirect to profile screen
    navigate("/landlord/notifications");
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-semibold">My Properties</h1>
          <div className="flex items-center">
            <div
              className="relative cursor-pointer"
              onClick={handleNotificationsClick}
            >
              <FontAwesomeIcon icon={faBell} className="text-xl mr-4" />
              {notificationsCount > 0 && (
                <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center">
                  {notificationsCount}
                </span>
              )}
            </div>
            <FontAwesomeIcon
              icon={faUser}
              className="text-xl mr-4 cursor-pointer"
              onClick={handleProfileClick}
            />
            <FontAwesomeIcon
              icon={faSignOutAlt}
              className="text-xl cursor-pointer"
              onClick={handleLogoutClick}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {properties.map((property) => (
            <div
              key={property.id}
              className="bg-white shadow-md rounded-md p-4"
              onClick={() => handlePropertyClick(property.property_id)}
            >
              <h2 className="text-lg font-semibold mb-2">{property.title}</h2>
              <img
                src={property.photo}
                alt={property.title}
                className="w-full h-40 object-cover mb-2"
              />
              <p className="text-xs text-gray-500">
                Location: {property.location}
              </p>
              <p className="text-xs text-gray-500">County: {property.county}</p>
              <p className="text-sm text-gray-600 mb-4">
                Description:{property.description}
              </p>
            </div>
          ))}
          {showAddPropertyForm ? (
            <div className="bg-white shadow-md rounded-md p-4">
              <form onSubmit={handleSubmitProperty}>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  placeholder="Title"
                  className="block w-full border border-gray-300 rounded-md p-2 mb-2"
                  required
                />
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  placeholder="Description"
                  className="block w-full border border-gray-300 rounded-md p-2 mb-2"
                  rows="3"
                  required
                ></textarea>
                <input
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  placeholder="Location"
                  className="block w-full border border-gray-300 rounded-md p-2 mb-2"
                  required
                />
                <input
                  type="text"
                  name="county"
                  value={formData.county}
                  onChange={handleInputChange}
                  placeholder="County"
                  className="block w-full border border-gray-300 rounded-md p-2 mb-2"
                  required
                />
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleUploadPhoto}
                  className="block w-full border border-gray-300 rounded-md p-2 mb-2"
                  required
                />
                <button
                  type="submit"
                  className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition-colors duration-300"
                >
                  Add Property
                </button>
              </form>
            </div>
          ) : (
            <div
              className="bg-white shadow-md rounded-md p-4 flex flex-col items-center justify-center cursor-pointer"
              onClick={handleAddProperty}
            >
              <FontAwesomeIcon icon={faPlus} className="text-3xl mb-2" />
              <p className="text-sm">Add Property</p>
            </div>
          )}
        </div>
      </div>
    </AuthGuard>
  );
};

export default Home;
