import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";

const UpdateWaterUnitsPage = () => {
  const navigate = useNavigate();
  const [newAmount, setNewAmount] = useState("");

  const handleUpdateWaterUnits = () => {
    const waterUnitsData = JSON.parse(localStorage.getItem("waterUnits"));
    const waterUnitsId = waterUnitsData.water_unit_id;
    const token = localStorage.getItem("token");

    // Create an object containing the amount
    const requestBody = {
      amount: newAmount,
    };

    // Send a PATCH request to update water units using axios
    axios
      .patch(
        `https://backend.inyumba.co.ke/iNyumba/waterunits/updatewaterunits/${waterUnitsId}`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json", // Set content type to application/json
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);
        navigate(-1);
      })
      .catch((error) => {
        toast.error(error.response.data.response);
        console.error("Error updating water units:", error);
      });
  };

  const handleNewAmountChange = (e) => {
    // Update the newAmount state with the value from the input field
    setNewAmount(e.target.value);
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Update Water Units</h1>
        <div className="mb-4">
          <label htmlFor="newAmount" className="block text-sm font-semibold">
            New Amount:
          </label>
          <input
            type="number"
            id="newAmount"
            className="w-full border rounded-md py-2 px-3"
            value={newAmount}
            onChange={handleNewAmountChange} // Update the newAmount state when input changes
          />
        </div>
        <button
          onClick={handleUpdateWaterUnits}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Update Water Units
        </button>
      </div>
    </AuthGuard>
  );
};

export default UpdateWaterUnitsPage;
