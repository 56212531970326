import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { AuthGuard } from "../App";

const RecordsPage = () => {
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const token = localStorage.getItem("token");
  const PropertyId = localStorage.getItem("selectedPropertyId");

  useEffect(() => {
    // Fetch records
    if (token) {
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/viewrecordsrentlandlord/${PropertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setRecords(response.data.response);
        })
        .catch((error) => {
          console.error("Error fetching records:", error);
        });
    }
  }, [token]);

  const handleDownloadCSV = () => {
    // Fetch and download CSV file
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/reportsdownload/${PropertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob", // Important for file download
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "inyumbapaidrentrecords.csv");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading CSV:", error);
      });
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const maskPhone = (phone) => {
    if (!phone) return ""; // Handle undefined or null phone numbers
    return phone.slice(0, 3) + "***" + phone.slice(-4); // Mask middle digits
  };

  return (
    <AuthGuard>
      <div className="flex flex-col items-center">
        {/* Go back button */}
        <button
          onClick={() => navigate(-1)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
          Back
        </button>

        {/* Records List */}
        <div className="w-full max-w-4xl">
          <h1 className="text-3xl font-semibold mb-4">Records</h1>
          <button
            onClick={handleDownloadCSV}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md m-2"
          >
            Download CSV
          </button>
          {records.length > 0 ? (
            <table className="table-auto w-full mb-4">
              <thead>
                <tr>
                  <th className="px-4 py-2">Record ID</th>
                  <th className="px-4 py-2">Name</th>
                  <th className="px-4 py-2">Phone</th>
                  <th className="px-4 py-2">House Number</th>
                  <th className="px-4 py-2">Water Bill</th>
                  <th className="px-4 py-2">Rent Bill</th>
                  <th className="px-4 py-2">Created At</th>
                </tr>
              </thead>
              <tbody>
                {records.map((record) => (
                  <tr key={record.record_id}>
                    <td className="border px-4 py-2">{record.record_id}</td>
                    <td className="border px-4 py-2">{record.user_name}</td>
                    <td className="border px-4 py-2">
                      {maskPhone(record.phone)}
                    </td>
                    <td className="border px-4 py-2">{record.house_number}</td>
                    <td className="border px-4 py-2">{record.water_bill}</td>
                    <td className="border px-4 py-2">{record.rent_bill}</td>
                    <td className="border px-4 py-2">{record.created_at}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No records found.</p>
          )}
        </div>
      </div>
    </AuthGuard>
  );
};

export default RecordsPage;
