import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { AuthGuard } from "../App";

const PropertyPage = () => {
  const { selectedPropertyId } = useParams();
  const navigate = useNavigate();
  const [property, setProperty] = useState(null);
  const [balance, setBalance] = useState(null);
  const token = localStorage.getItem("token");
  const PropertyId = localStorage.getItem("selectedPropertyId");

  useEffect(() => {
    // Fetch property details
    if (token) {
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/admin/properties/records/${PropertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setProperty(response.data.response);
        })
        .catch((error) => {
          console.error("Error fetching property:", error);
        });
    }
  }, []);

  const handleUpdateProperty = () => {
    // Navigate to update property page
    navigate(`/landlord/updateproperty`);
  };

  const handleWithdrawals = () => {
    // Navigate to withdrawals page
    navigate(`/landlord/withdrawals`);
  };

  const handleDeposits = () => {
    // Navigate to withdrawals page
    navigate(`/landlord/deposits`);
  };

  const handleComments = () => {
    // Navigate to comments page
    navigate(`/landlord/comments`);
  };

  const handleVacating = () => {
    // Navigate to vacating page
    navigate(`/landlord/vacating`);
  };

  const handleCaretaker = () => {
    // Navigate to caretaker page
    navigate(`/landlord/caretaker`);
  };

  const handleMaintenance = () => {
    // Navigate to maintenance page
    navigate(`/landlord/maintenance`);
  };

  const handleReservations = () => {
    // Navigate to reservations page
    navigate(`/landlord/reservations`);
  };

  const handlePropertyKYC = () => {
    // Navigate to property KYC page
    navigate(`/landlord/propertykyc`);
  };

  const handleWaterUnits = () => {
    // Navigate to water units page
    navigate(`/landlord/waterunits`);
  };

  const handleAmenities = () => {
    // Navigate to amenities page
    navigate(`/landlord/amenities`);
  };

  const handleRentals = () => {
    // Navigate to rentals page
    navigate(`/landlord/rentals`);
  };
  const handlePaymentRecords = () => {
    // Navigate to rentals page
    navigate(`/landlord/recordsview`);
  };

  const handleTransactionsView = () => {
    // Navigate to rentals page
    navigate(`/landlord/transactionsview`);
  };

  const handleSettlementsView = () => {
    // Navigate to rentals page
    navigate(`/landlord/settlement`);
  };

  return (
    <AuthGuard>
      <div className="flex flex-col items-center">
        {/* Go back button */}
        <button
          onClick={() => navigate(-1)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
          Back
        </button>

        {/* Property details */}
        {property && (
          <div>
            <h1 className="text-3xl font-semibold mb-4">{property.title}</h1>
            <div className="mb-4">
              <img
                src={property.photo}
                alt={property.title}
                className="w-full sm:max-w-xs md:max-w-sm lg:max-w-md max-h-60 object-cover cursor-pointer rounded-md"
              />
              {/* <GlassMagnifier imageSrc={property.photo} alt={property.title} /> */}
            </div>
            <p className="mb-2">
              <strong>Location:</strong> {property.location}
            </p>
            <p className="mb-2">
              <strong>County:</strong> {property.county}
            </p>
            <p className="mb-2">
              <strong>Description:</strong> {property.description}
            </p>
            <p className="mb-2">
              <strong>Approval Status:</strong> {property.approvalstatus}
            </p>
          </div>
        )}

        {/* Buttons */}
        <div className="flex flex-wrap justify-center mt-8">
          <button
            onClick={handleUpdateProperty}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md m-2"
          >
            Update Property
          </button>
          <button
            onClick={handleWithdrawals}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md m-2"
          >
            Withdraw
          </button>
          <button
            onClick={handleDeposits}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md m-2"
          >
            Deposit
          </button>
          <button
            onClick={handleComments}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md m-2"
          >
            Comments
          </button>
          <button
            onClick={handleAmenities}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md m-2"
          >
            Amenities
          </button>
          <button
            onClick={handleWaterUnits}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md m-2"
          >
            Water Units
          </button>
          <button
            onClick={handleCaretaker}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md m-2"
          >
            Caretaker
          </button>
          <button
            onClick={handleReservations}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md m-2"
          >
            Reservations
          </button>
          <button
            onClick={handleMaintenance}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md m-2"
          >
            Maintenance
          </button>
          <button
            onClick={handleVacating}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md m-2"
          >
            Vacating
          </button>
          <button
            onClick={handlePropertyKYC}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md m-2"
          >
            Property KYC
          </button>
          <button
            onClick={handleRentals}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md m-2"
          >
            Rentals
          </button>
          <button
            onClick={handlePaymentRecords}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md m-2"
          >
            Rent Payment Records
          </button>

          <button
            onClick={handleTransactionsView}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md m-2"
          >
            Transactions View
          </button>

          <button
            onClick={handleSettlementsView}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md m-2"
          >
            Settlement View
          </button>
        </div>
      </div>
    </AuthGuard>
  );
};

export default PropertyPage;
