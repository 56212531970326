import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner"; // Import loader

const TransactionsViewPage = () => {
  const propertyId = localStorage.getItem("selectedPropertyId");
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  useEffect(() => {
    const fetchTransactions = () => {
      setIsLoading(true); // Set loading state to true
      const token = localStorage.getItem("token");
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/alltransactionsview/${propertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setTransactions(response.data.response); // Assuming the response structure
          setIsLoading(false); // Set loading state to false
        })
        .catch((error) => {
          console.error("Error fetching transactions:", error);
          toast.error(
            error.response?.data?.response || "Error fetching transactions"
          );
          setIsLoading(false); // Set loading state to false
        });
    };

    fetchTransactions(); // Call the function immediately after component mounts
  }, [propertyId]); // Dependency array includes propertyId

  const handleDownloadCSV = () => {
    const token = localStorage.getItem("token");
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/reportsdownloadtransactions/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob", // Important for file download
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "inyumbatransactions.csv");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading report:", error);
        toast.error("Failed to download the report.");
      });
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Transactions View</h1>

        <button
          onClick={handleDownloadCSV}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
        >
          Download Transactions Report
        </button>

        {isLoading ? (
          <div className="flex justify-center">
            <Oval
              height={40}
              width={40}
              color="blue"
              ariaLabel="loading-indicator"
            />
          </div>
        ) : (
          <table className="mt-4 w-full">
            <thead>
              <tr>
                <th className="px-4 py-2">Date of Transaction</th>
                <th className="px-4 py-2">Transaction ID</th>
                <th className="px-4 py-2">Channel</th>
                <th className="px-4 py-2">Transaction Amount</th>
                <th className="px-4 py-2">Running Balance</th>
              </tr>
            </thead>
            <tbody>
              {transactions.length > 0 ? (
                transactions.map((transaction) => (
                  <tr key={transaction.transaction_id}>
                    <td className="border px-4 py-2">
                      {new Date(transaction.created_at).toLocaleDateString()}
                    </td>
                    <td className="border px-4 py-2">
                      {transaction.transaction_id}
                    </td>
                    <td className="border px-4 py-2">
                      {transaction.reference}
                    </td>
                    <td className="border px-4 py-2">{transaction.amount}</td>
                    <td className="border px-4 py-2">
                      {transaction.running_balance}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="border px-4 py-2 text-center">
                    No transactions found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </AuthGuard>
  );
};

export default TransactionsViewPage;
