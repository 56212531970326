import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";

const AddPropertyKYCPage = () => {
  const [propertyId, setPropertyId] = useState("");
  const [ownerId, setOwnerId] = useState("");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    reg_certificate: "",
    cr12_certificate: "",
    utility_bills: "",
    business_permit: "",
    business_pin: "",
  });
  const [loading, setLoading] = useState(false); // State to manage loading status

  useEffect(() => {
    // Fetch propertyId from local storage
    const selectedPropertyId = localStorage.getItem("selectedPropertyId");
    setPropertyId(selectedPropertyId);

    // Fetch ownerId from local storage
    const userDataString = localStorage.getItem("user");
    const userData = JSON.parse(userDataString);
    const ownerId = userData.unique;
    setOwnerId(ownerId);
  }, []);

  const handleFileSelect = async (e, fieldName) => {
    const file = e.target.files[0];
    setLoading(true); // Set loading to true when file upload starts
    try {
      const formDataCopy = { ...formData }; // Create a copy of the formData state
      const formDataEntry = formDataCopy[fieldName]; // Get the existing data for the field

      const formDataToSend = new FormData();
      formDataToSend.append("file", file);
      const response = await axios.post(
        "https://backend.inyumba.co.ke/iNyumba/kyc/kyc/upload",
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Update the formData state by merging the existing data with the new file URI
      formDataCopy[fieldName] = response.data.response.coverUri;
      setFormData(formDataCopy);
    } catch (error) {
      console.error(`Error uploading ${fieldName}:`, error);
    } finally {
      setLoading(false); // Set loading to false when file upload finishes
    }
  };

  const handleSubmit = async () => {
    setLoading(true); // Set loading to true when form submission starts
    try {
      // Check if all files are uploaded
      if (Object.values(formData).some((value) => value === "")) {
        toast.error("Not all files uploaded");
        return;
      }

      // Submit KYC data
      const postData = {
        ...formData,
        propertyId,
        ownerId,
      };
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        `https://backend.inyumba.co.ke/iNyumba/propertykyc/add/property/${propertyId}/owner/${ownerId}`,
        postData,
        config
      );

      toast.success(response.data.response);
      navigate(-1);
    } catch (error) {
      toast.error(error.response.data.response);
      console.error("Error submitting KYC data:", error);
    } finally {
      setLoading(false); // Set loading to false when form submission finishes
    }
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Property KYC</h1>
        <h1 className="text-3xl font-semibold mb-4">
          Please upload all the documents as it is crucial for approval
        </h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* File selectors for document uploads */}
          <div className="flex flex-col">
            <label htmlFor="reg_certificate_file" className="mb-1">
              Registration Certificate File
            </label>
            <input
              type="file"
              id="reg_certificate_file"
              onChange={(e) => handleFileSelect(e, "reg_certificate")}
              className="w-full"
            />
            {loading && <div>Loading...</div>} {/* Loader for file upload */}
          </div>
          {/* File selectors for document uploads */}
          <div className="flex flex-col">
            <label htmlFor="cr12_certificate_file" className="mb-1">
              CR12 Certificate File
            </label>
            <input
              type="file"
              id="cr12_certificate_file"
              onChange={(e) => handleFileSelect(e, "cr12_certificate")}
              className="w-full"
            />
            {loading && <div>Loading...</div>} {/* Loader for file upload */}
          </div>
          {/* Utility Bills File */}
          <div className="flex flex-col">
            <label htmlFor="utility_bills_file" className="mb-1">
              Utility Bills File
            </label>
            <input
              type="file"
              id="utility_bills_file"
              onChange={(e) => handleFileSelect(e, "utility_bills")}
              className="w-full"
            />
            {loading && <div>Loading...</div>} {/* Loader for file upload */}
          </div>
          {/* Business Permit File */}
          <div className="flex flex-col">
            <label htmlFor="business_permit_file" className="mb-1">
              Business Permit File
            </label>
            <input
              type="file"
              id="business_permit_file"
              onChange={(e) => handleFileSelect(e, "business_permit")}
              className="w-full"
            />
            {loading && <div>Loading...</div>} {/* Loader for file upload */}
          </div>
          {/* Business PIN File */}
          <div className="flex flex-col">
            <label htmlFor="business_pin_file" className="mb-1">
              Business PIN File
            </label>
            <input
              type="file"
              id="business_pin_file"
              onChange={(e) => handleFileSelect(e, "business_pin")}
              className="w-full"
            />
            {loading && <div>Loading...</div>} {/* Loader for file upload */}
          </div>

          {/* Submit button */}
          <button
            type="button"
            onClick={handleSubmit}
            disabled={loading}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            {loading ? "Loading..." : "Submit"}
          </button>
        </form>
      </div>
    </AuthGuard>
  );
};

export default AddPropertyKYCPage;
