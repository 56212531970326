import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";

const Rentals = () => {
  const [rentals, setRentals] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const propertyId = localStorage.getItem("selectedPropertyId");
    const token = localStorage.getItem("token");

    if (propertyId && token) {
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/admin/rentals/propertyall/${propertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setRentals(response.data.response);
        })
        .catch((error) => {
          console.error("Error fetching rentals:", error);
        });
    }
  }, []);

  const handleAddRental = () => {
    setShowAddModal(true);
  };

  const closeModal = () => {
    setShowAddModal(false);
  };

  const addRental = () => {
    const propertyId = localStorage.getItem("selectedPropertyId");
    const token = localStorage.getItem("token");
    const rentPrice = document.getElementById("rentPrice").value;
    const houseType = document.getElementById("houseType").value;
    const description = document.getElementById("description").value;
    const houseNumber = document.getElementById("houseNumber").value;

    if (
      !propertyId ||
      !token ||
      !rentPrice ||
      !houseType ||
      !description ||
      !houseNumber
    ) {
      console.error("Required data not found.");
      return;
    }

    const newRentalData = {
      rent_price: rentPrice,
      house_type: houseType,
      description: description,
      house_number: houseNumber,
    };

    axios
      .post(
        `https://backend.inyumba.co.ke/iNyumba/admin/rentals/property/${propertyId}`,
        newRentalData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);
        closeModal();
        // Optionally, update rentals list
        setRentals([...rentals, response.data]);
      })
      .catch((error) => {
        toast.error(error.response.data.response);
        console.error("Error adding rental:", error);
      });
  };

  const handleUpdateRental = (rentalId) => {
    localStorage.setItem("selectedRentalId", rentalId);
    navigate("/landlord/updaterental");
  };

  const handleViewRental = (rentalId) => {
    localStorage.setItem("selectedRentalId", rentalId);
    navigate("/landlord/specificrental");
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Rentals</h1>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {rentals.map((rental) => (
            <div key={rental.id} className="bg-white shadow-md rounded-md p-4">
              <p>
                <strong>Rent Price:</strong> {rental.rent_price}
              </p>
              <p>
                <strong>House Type:</strong> {rental.house_type}
              </p>
              <p>
                <strong>Description:</strong> {rental.description}
              </p>
              <p>
                <strong>House Number:</strong> {rental.house_number}
              </p>
              <button
                onClick={() => handleUpdateRental(rental.rental_id)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2"
              >
                Update
              </button>
              <button
                onClick={() => handleViewRental(rental.rental_id)}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2"
              >
                Add more things
              </button>
            </div>
          ))}
        </div>

        <button
          onClick={handleAddRental}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-8"
        >
          Add Rental
        </button>

        {/* Add modal for add rental */}
        <Modal
          isOpen={showAddModal}
          onRequestClose={closeModal}
          className="modal flex items-center justify-center"
        >
          <div className="bg-white w-1/3 p-6 rounded-lg">
            <h2 className="text-2xl font-semibold mb-4">Add Rental</h2>
            <div className="mb-4">
              <label
                htmlFor="rentPrice"
                className="block text-sm font-semibold"
              >
                Rent Price:
              </label>
              <input
                type="number"
                id="rentPrice"
                className="w-full border rounded-md py-2 px-3"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="houseType"
                className="block text-sm font-semibold"
              >
                House Type:
              </label>
              <input
                type="text"
                id="houseType"
                className="w-full border rounded-md py-2 px-3"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="description"
                className="block text-sm font-semibold"
              >
                Description:
              </label>
              <textarea
                id="description"
                className="w-full border rounded-md py-2 px-3"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="houseNumber"
                className="block text-sm font-semibold"
              >
                House Number:
              </label>
              <input
                type="text"
                id="houseNumber"
                className="w-full border rounded-md py-2 px-3"
              />
            </div>
            <div className="flex justify-end">
              <button
                onClick={addRental}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
              >
                Add
              </button>
              <button
                onClick={closeModal}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </AuthGuard>
  );
};

export default Rentals;
